import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Button from "Commons/Button";
import useLang from "Commons/Hooks/useLang";
import "./styles.scss";

const SingleService = ({ title = "", paragraphs = [], icon, fieldKey }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [lang] = useLang();

  const data = useStaticQuery(graphql`
    query getSingleServiceTexts {
      allContentfulCommonTexts {
        nodes {
          seeMore
          seeLess
        }
      }
    }
  `);

  const commonTexts = data.allContentfulCommonTexts.nodes[lang];

  const toParagraph = (para) => {
    if (para.indexOf("|") >= 0) {
      const separatedParagraph = para.split("|");
      return (
        <ul>
          {separatedParagraph.map((singlePara) => (
            <li>{singlePara}</li>
          ))}
        </ul>
      );
    }
    return <p>{para}</p>;
  };

  return (
    <div className="single-service" id={fieldKey}>
      <div className="single-service__icon">{icon}</div>
      <div className="single-service__texts">
        <h2>{title}</h2>
        {isOpen ? paragraphs.map(toParagraph) : <p>{paragraphs[0]}</p>}
        <div>
          <Button
            text={isOpen ? commonTexts.seeLess : commonTexts.seeMore}
            onClick={() => setIsOpen(!isOpen)}
            className="hollow"
          />
        </div>
      </div>
    </div>
  );
};

export default SingleService;
