import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Row, Col } from "react-grid-system";
import Cisofy from "Images/services/partners/partner-cisofy-02.svg";
import Fraudwatch from "Images/services/partners/partner-fraudwatch-04.svg";
import Netsparker from "Images/services/partners/partner-netsparker-06.svg";
import Portswigger from "Images/services/partners/partner-portswigger-01.svg";
import Vfeed from "Images/services/partners/partner-vfeed-03.svg";
import Wallarm from "Images/services/partners/partner-wallarm-05.svg";
import useLang from "Commons/Hooks/useLang";

import "./styles.scss";

const Partners = () => {
  const [lang] = useLang();
  const partnerList = [
    <Cisofy />,
    <Fraudwatch />,
    <Netsparker />,
    <Portswigger />,
    <Vfeed />,
    <Wallarm />,
  ];

  const mappedPartners = partnerList.map((partner, index) => {
    return (
      <Col
        xs={6}
        md={4}
        lg={4}
        key={index}
        className="service-partners_partner"
      >
        {partner}
      </Col>
    );
  });

  const data = useStaticQuery(graphql`
    query getPartnersTexts {
      allContentfulServicesTexts {
        nodes {
          partnersTitle
          partnersDescription {
            partnersDescription
          }
        }
      }
    }
  `);

  const commonTexts = data.allContentfulServicesTexts.nodes[lang];

  return (
    <div className="services-partners">
      <Container>
        <div>
          <p className="services-partners_title">{commonTexts.partnersTitle}</p>
        </div>
        <Row>
          <Col xs={12} md={5} lg={5} className="services-partners_subtitle">
            <p>{commonTexts.partnersDescription.partnersDescription}</p>
          </Col>
          <Col md={7} lg={7}>
            <Row>{mappedPartners}</Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Partners;
