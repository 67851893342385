import React from "react";
import { graphql } from "gatsby";
import { Container, Col, Row } from "react-grid-system";
import Header from "Commons/Header";
import Footer from "Commons/Footer";
import Button from "Commons/Button";
import PageHeader from "Commons/PageHeader";
import useLang from "Commons/Hooks/useLang";
import ApplicationSecurity from "Images/services/application-security.svg";
import MobileSecurity from "Images/services/mobile-security.svg";
import BugBountyProgramManagement from "Images/services/bug-bounty-program-management.svg";
import ClientSideAttacks from "Images/services/client-side-attacks.svg";
import PenetrationTesting from "Images/services/penetration-testing.svg";
import Hardening from "Images/services/hardening.svg";
import SecurityAwareness from "Images/services/security-awareness.svg";
import ThreatModeling from "Images/services/threat-modeling.svg";

import SingleService from "Components/Services/SingleService";
import { Helmet } from "react-helmet";
import Partners from "../components/Services/Partners";

import brochure from "../assets/ArtsSec_Brochure.pdf";

const toSingleService = ({ title, description, icon, fieldKey }, index) => (
  <Col xs={12} lg={6}>
    <SingleService
      title={title}
      paragraphs={description}
      fieldKey={fieldKey}
      icon={icon}
    />
  </Col>
);

const icons = {
  "application-security": <ApplicationSecurity />,
  "mobile-security": <MobileSecurity />,
  "bug-bounty-program-management": <BugBountyProgramManagement />,
  "client-side-attacks": <ClientSideAttacks />,
  "penetration-testing": <PenetrationTesting />,
  hardening: <Hardening />,
  "security-awareness": <SecurityAwareness />,
  "threat-modeling": <ThreatModeling />,
};

const ServicesPage = ({ data }) => {
  const [lang] = useLang();
  const { title, pageTitle, servicesList, downloadBrochure } =
    data.allContentfulServicesTexts.nodes[lang];

  const mappedServices = servicesList.data.map((service) => ({
    ...service,
    icon: icons[service.fieldKey],
  }));

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header />
      <PageHeader text={title} />
      <Container>
        <Row>{mappedServices.map(toSingleService)}</Row>
        <Row justify="center">
          <a href={brochure} download>
            <Button
              text={downloadBrochure}
              className="services-brochure-button"
            />
          </a>
        </Row>
      </Container>
      <Partners />
      <Footer />
    </>
  );
};

export const query = graphql`
  query getServicesPageTexts {
    allContentfulServicesTexts {
      nodes {
        pageTitle
        title
        downloadBrochure
        partnersTitle
        partnersDescription {
          partnersDescription
        }
        servicesList {
          data {
            description
            title
            fieldKey
          }
        }
      }
    }
  }
`;

export default ServicesPage;
