import React from "react";
import { Container } from "react-grid-system";
import "./styles.scss";

const PageHeader = ({ text }) => {
  return (
    <header className="_page-header">
      <Container>
        <h1>{text}</h1>
      </Container>
    </header>
  );
};

export default PageHeader;
